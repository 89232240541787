/* // Large devices (desktops, 992px and up) */
@media (min-width: 780px) {
  .site-content {
    display: none;
  }
  .hidden {
    display: block !important;
    height: 100vh;
    width: 100vw;
    background-color: #046a9e;
    background-image: url('../src/images/background_blue_gradient.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
  }
  .desktop-banner {
    margin: 15hv 15vw !important;
    overflow: auto;
    padding: 15vh 5vw;
    font-size: large;
    color: white;
    vertical-align: center;
  }
  .little-square {
    margin: 0 auto;
    width: 40%;
  }
  .littler-square {
    margin: 0 auto;
    width: 20%;
  }

  .directive-card {
    border: 5px solid black !important;
    border-radius: 1.5rem !important;
    width: 300px;
    height: 550px;
  }
}

/* ------- general/sitewide -------- */
html,
body {
  width: 100%;
  height: 100%;
  /* margin-bottom: 20vw; */
  /* padding: 0px; */
  overflow-x: hidden;
  background-color: #ecf3f9 !important;
  /* padding-bottom: 10vh;  */
}
ul {
  list-style: none;
}

a {
  color: black !important;
}
div.container {
  min-width: 100vw !important;
  /* background-color: #087cb8 !important; */
}
.add-padding-bottom {
  /* separate so views arent overlayed */
  padding-bottom: 11vh;
}
.hidden {
  display: none;
}
/* ------- Navigation -------- */
nav {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  background-color: #f5f6f8;
}
.nav-item {
  width: calc(100% / 4);
  height: 10vh;
  text-align: center;
  display: flex !important;
  justify-content: space-evenly;
  align-items: center;
}
.nav-tabs > .nav-link {
  transition: 0.5s ease-in;
}
.nav-tabs > .nav-link:hover {
  background-color: #303136 !important;
  border: medium none;
  border-radius: 0;
  color: #fff !important;
  font-weight: bold;
  /* transition: 0.5s ease-out; */
}

/* ----------- Account------------- */
.btn-social {
  min-width: 50vw !important;
  /* margin: auto; */
  padding: 0.3rem;
  margin: 0.3rem 0;
}
.social-links {
  /* border: 1px solid black; */
  padding: 0;
  text-align: center;
}
.profile-photo {
  border-radius: 50%;
}

.usercard {
  background-color: #bebebe;
  background-image: url('../src/images/trianglify-lowres.png');
  background-size: 150%;
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  margin: -1em;
  border-bottom: 3px ridge #303030;
  padding-top: 1em;
}

/* -------------- Login/Signup ------------------- */

.highlight-link {
  color: blue !important;
}
.cta {
  line-height: 0.3;
  margin-top: 2rem;
}
.page-bg {
  /* background-color: #0d4062; */
  /* height: 90vh; */
  width: 100vw;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  overflow: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.no-padding {
  padding-bottom: 0vh !important;
}

.page-inner {
  /* background-color: #f5f6f8; */
  margin: 10vw;
  padding: 10vw;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  overflow: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.btn-signin {
  padding: 0.3rem;
  margin: 0.3rem 0;
  text-align: center;
  background-color: #087cb8;
  color: #f5f6f8;
}

.signin-logo {
  width: 30vw;
  margin: 0vw;
}

/* --------- Home ------------------ */
/* Header Parallax Element Style*/
.paral {
  min-height: 10vh;
  background-attachment: fixed;
  background-size: cover;
  background-position: 50% 50%;
}
/* Heading for Parallax Section */
.paral h1 {
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
}
/* Image for Parallax Home Section */
.paralsec {
  background-image: url('https://img.webnots.com/2017/05/parallax.jpg');
}
.progress {
  width: auto !important;
}
.file-uploader {
  background-color: #fff !important;
  /* padding: 0.5em; */
  margin-bottom: 0.5em;
  border-style: none;
  border-radius: 5px;
  position: relative;
}
button a {
  color: white !important;
  font-weight: bold;
}
/* input#file-upload-button {
  background-color: #087cb8 !important;
  font-weight: bold !important;
} */
/* .jumbotron {
  border-radius: 0 !important;
  margin-bottom: 0 !important;
} */

.section-divider {
  width: 100%;
  border-top: 1px solid black;
  padding-top: 1rem;
  margin: 1rem 0 1rem 0;
}

.non-nav {
  position: unset;
  justify-content: space-evenly;
  border-bottom: 5px solid #303136;
}
.non-nav > a.nav-item {
  border-radius: 0;
  width: calc(100vw / 2) !important;
}
.non-nav > a:hover,
a.active {
  color: #fff !important;
  font-weight: bold;
  background-color: #303136 !important;
}

.company-list {
  padding: 1rem;
  width: 100vw;
  text-align: left;
}

.form-input,
select.form-control {
  padding: 0.5em;
  margin-bottom: 0.5em;
  border-style: none;
  border-radius: 5px;
  font-weight: bold;
}

::placeholder,
select option:disabled,
select option[disabled] {
  color: rgba(0, 0, 0, 0.4) !important;
}

p.comp-item {
  margin-bottom: 0 !important;
  line-height: 1.3;
}
p hr {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}
.comp-container {
  border: 1px solid black;
}

.btn-li {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  display: block;
  padding: 0.5rem;
  margin-top: 0.4rem;
}

li.row {
  border-radius: 5px;
  margin: 0.5rem 0.1rem;
  text-align: left;
  padding: 0.3em;
  background-color: #fff;
  justify-content: space-between;
}

/* li.row:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.3);
} */

.form-row {
  justify-content: space-evenly;
}
.text-center.edit-container {
  margin-top: 1rem;
  width: 100vw;
  justify-content: space-evenly;
}
.form-row.edit-btn-container {
  width: 40vw;
  display: flex;
  justify-content: space-evenly;
  margin: 0 30vw;
}
.form-group.col-10 {
  margin: auto;
}
.justify-me {
  margin: 0.3em auto;
  justify-content: space-evenly;
  display: flex;
  /* flex-grow: inherit; */
  /* align-content: center; */
  /* text-align: center; */
  width: 100vw;
  /* border: 1px solid black; */
}

.btn-bot {
  width: 45vw;
}
ul.building-list {
  padding-inline-start: unset;
  /* padding: 1rem; */
  width: 100vw;
  text-align: left;
}

ul.report-list {
  padding-inline-start: unset;
  padding-left: 1em;
  padding-right: 1em;
  width: 100vw;
  text-align: left;
}

p.comp-item.report-desc {
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* max-width: 80vw; */
}
/* ------- Landing ---------- */
.landing-container {
  height: 100vh;
  width: 100vw;
  background-image: url('../src/images/landing.gif');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

/* .landing-logo {
  height: 10vh;
  width: 10vw;
} */

.square {
  width: 90%;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
}

.square:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.title {
  font-size: 2em;
  color: #fff;
  font-weight: bolder;
}

.fade-in {
  width: 30vw;
  height: 30vw;
  margin: 0 20vw;
  animation-fill-mode: both;
  animation: fadeIn ease 05s;
  -webkit-animation: fadeIn ease 05s;
  -moz-animation: fadeIn ease 05s;
  -o-animation: fadeIn ease 05s;
  -ms-animation: fadeIn ease 05s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* ------- Details ---------- */
/* report */
.content-wrapper {
  width: 100vw;
}
.content-wrapper h2 {
  text-align: center;
}

.r-details-card {
  background-color: #fff;
  border-radius: 5px;
  padding: 0.5em;
  margin-top: 0.5rem;
}

/* company */

.ul-comp-list {
  padding: 0;
}
